import { useLocation } from '@gatsbyjs/reach-router'
import queryString from 'query-string'
import React, { FC, useEffect } from 'react'

import { CallbackPage } from '../components/CallbackPage'
import { isValidDispensaryPath } from '../lib/dispensaries'
import { usePageUrl } from '../lib/pages'

export const DispensaryLogoutPage: FC = () => {
  const { navigate } = usePageUrl()
  const { search } = useLocation()
  const { path } = queryString.parse(search || '')

  useEffect(() => {
    if (!path || typeof path !== 'string') {
      return
    }
    if (isValidDispensaryPath(path)) {
      navigate(path)
    }
  }, [navigate, path])

  return <CallbackPage />
}

export default DispensaryLogoutPage
