import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import styled, { css } from 'styled-components'

import Head from '../layouts/Head'
import Layout from './global/Layout'
import Spinner from './Spinner'

const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grayscale.white};
  `}
`

export const CallbackPage: FC = () => {
  return (
    <>
      <Head title="Redirecting..." />
      <GlobalStyle includeTypography />
      <Layout>
        <Container>
          <>
            <Helmet title="Redirecting..." />
            <Spinner loading />
          </>
        </Container>
      </Layout>
    </>
  )
}
